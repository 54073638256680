#aide-3d-model-canvas {
  background-color: #000;
  overflow: visible !important;
}

.model-loader {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.annotation-expansion-container {
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding: 6px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -1px;
  left: -1px;
  min-width: 350px;
  width: 100%;
  max-height: 245px;
  height: 245px;
  transform-origin: top left;
  transition: all 0.3s;
  border-radius: 12px;
  border: 0.921px solid #FFF;
  background: #F5F5F5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.annotation-expansion-container video {
  border-radius: 8px;
}
.annotation-expansion-container h3 {
  display: flex;
  margin: 0 0 6px 0;
  text-align: left;
  width: 95%;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  line-height: 140%; /* 16.8px */
  font-weight: 600;
}
.annotation-expansion-container.expand-video-container h3 {
  font-size: clamp(16px, 1vw + 0.5rem, 1.8rem);
  line-height: clamp(18px, 1vw + 0.5rem, 39.2px);
  font-weight: 400;
  color: #F5F5F5;
  margin: 0 5px 10px 0;
}
.annotation-expansion-container.expand-video-container h3::before {
  display: none;
}

.annotation-expansion-container:not(:is(.expand-video-container)):hover h3 {
  color:#544FF8;
}

.annotation-expansion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.annotation-expansion-container .annotation-expansion-header .close-video-expand img {
  width: 30px;
  height: 30px;
}

.annotation-expansion-header .video-expand {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}
.annotation-expansion-container:hover .annotation-expansion-header .video-expand,
.annotation-expansion-container:hover .annotation-expansion-header .close-video-expand {
  visibility: visible;
  cursor: pointer;
}

.annotation-expansion-container .annotation-video-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: transparent;
  height: 40px;
  width: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.annotation-expansion-container .annotation-video-btn .pause-btn {
  width: 35px;
  height: 35px;
  display: none;
}

#aide-3d-model-canvas .active {
  z-index: 99999999 !important;
}

.annotation-box {
  position: relative;
}
.annotation-wrapper {
  transform: translate3d(calc(-50%), calc(-50%), 0);
}
.annotation {
  background: #F5F5F5;
  border: 0.92px solid #FFFFFF;
  color: #000;
  width: auto;
  border-radius: 25px;
  user-select: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  transform-origin: top left;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  padding: 6.14px;
}
.annotation .annotation-title {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: max-content;
  max-width: 185px;
  padding: 0 5px;
}

.reset-transform {
	position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.reset-transform > div {
  position: relative !important;
}

.annotation-wrapper .expand-video-container {
  position: fixed;
  top: 50%;
  left: 190%;
  margin: auto;
  transform: translate(-50%, -50%) !important;
  width: 45vw;
  max-height: 90vh;
  height: 65vh;
  padding: 40px;
  background: #2B2B2B;
  transform-origin: center center;
  border-radius: 28px;
  box-shadow: none;
  border: none;
}

.annotation-expansion-container.expand-video-container video {
  border-radius: 14px;
}

.annotation-expansion-video {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.model-video-overlay {
  background: #00000080;
  height: 120vh;
  left: -130px !important;
  position: absolute !important;
  top: -100px !important;
  transform: none !important;
  width: 115vw;
  z-index: 99999998 !important;
}

.hide-title {
  visibility: hidden;
}

#aide-3d-model-canvas .chip-default > div {
  transform-origin: top left; 
  transition: all 0.3s;
}

#aide-3d-model-canvas .show-chip > div, .show-chip-popup {
  opacity: 1;
  transform: scale(1) !important;
}

#aide-3d-model-canvas .hide-chip > div, .hide-chip-popup {
  opacity: 0;
  transform: scale(0.25) !important;
  pointer-events: none;
  z-index: -1;
}
